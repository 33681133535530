@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, app-root {
  background-color: var(--color-grey-100);
  height: 100%;
  display: block;
}

html.dark body {
  background-color: #131922;
}

html {
  @apply bg-gray-100 h-full;
}

body {
  @apply text-gray-700;
  font-family: "Inter", system-ui, -apple-system, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.wrapper {
  max-width: initial !important;
}

a {
  @apply cursor-pointer;
}


@layer base {
  :root {
    // background colors
    --color-bg-neutral: #ffffff; // white
    --color-bg-neutral-50: #fafafa; // neutral-50
    --color-bg-neutral-200: #e4e4e4; // neutral-200
    --color-bg-disabled: #eeeef0; // grey-50
    --color-bg-inversed: #f7f7f8; // grey-50
    --color-bg-brand: #701ef6; // empurple-700
    --color-bg-brand-hover: #460aa8; // empurple-900
    --color-bg-brand-pressed: #290273; // empurple-950
    --color-bg-dark: #1c423b; // moss-700;
    --color-bg-dark-hover: #4f7770; // moss-400;
    --color-bg-danger: #FDCBCB; // red-200

    // text colors
    --color-text-primary: #303038; // grey-900
    --color-text-subtle: #4a4a5a; // grey-700
    --color-text-subtlest: #8f91a1; // grey-400
    --color-text-inversed: #fafafa; // neutral-50
    --color-text-disabled: #b6b7c3; // grey-300
    --color-text-brand: #701ef6; // empurple-700
    --color-text-danger: #D92929; // red-600

    // border colors
    --color-border-main: #eeeef0; // grey-100
    --color-border-subtle: #f3f3f3; // neutral-100
    --color-border-hovered: #d8d9df; // grey-200
    --color-border-pressed: #b6b7c3; // grey-300
    --color-border-disabled: #b6b7c3; // grey-300
    --color-border-brand: #701ef6; // empurple-700
    --color-border-danger: #D92929; // red-600

    // Theme variables
    --color-primary: #701ef6; // empurple-700
    --color-primary-50: #f1efff; // empurple-50
    --color-primary-100: #ebe6ff; // empurple-100
    --color-primary-200: #d9d0ff; // empurple-200
    --color-primary-900: #460aa8; // empurple-900
    --color-primary-950: #290273; // empurple-950

    --color-secondary: #4a4a5a; // grey-700
    --color-secondary-50: #f7f7f8; // grey-50
    --color-secondary-100: #eeeef0; // grey-100
    --color-secondary-200: #d8d9df; // grey-200
    --color-secondary-300: #b6b7c3; // grey-300
    --color-secondary-400: #8f91a1; // grey-400
    --color-secondary-500: #717286; // grey-500
    --color-secondary-700: #4a4a5a; // grey-700
    --color-secondary-900: #303038; // grey-900

    --color-tertiary: #58acf2; // blue-500
    --color-tertiary-50: #f9fcfe; // blue-50
    --color-tertiary-200: #d0e7fb; // blue-200
    --color-tertiary-300: #aad4f8; // blue-300
    --color-tertiary-400: #83c0f5; // blue-400
    --color-tertiary-900: #123048; // blue-900

    --color-success: #437f0c; // green-700
    --color-success-50: #f5ffe6; // green-50
    --color-success-200: #d0f9c1; // green-200
    --color-success-300: #b4f09e; // green-300
    --color-success-400: #97e67a; // green-400
    --color-success-900: #305512; // green-900

    --color-danger: #b71e1e; // red-700
    --color-danger-200: #fdcbcb; // red-200
    --color-danger-300: #fba6a6; // red-300
    --color-danger-400: #f67373; // red-400
    --color-danger-500: #ec4141; // red-500
    --color-danger-600: #d92929; // red-600
    --color-danger-800: #971d1d; // red-800

    --color-light: #ffffff; // white
    --color-light-50: #fafafa; // neutral-50
    --color-light-100: #f3f3f3; // neutral-100

    --color-dark: #000000; // black

    --color-brand: #1c423b; // moss-900
    --color-brand-400: #4f7770; // moss-400

    --color-cyan-200: #ccebe3; // cyan-200
    --color-cyan-300: #a2dbce; // cyan-300
    --color-cyan-400: #75cab8; // cyan-400

    --color-purple-200: #e7e0fc; // purple-200
    --color-purple-300: #d5c6f9; // purple-300
    --color-purple-400: #c4aef7; // purple-400

    --color-pink-200: #fad0f2; // pink-200
    --color-pink-300: #f7aae7; // pink-300
    --color-pink-400: #f175d4; // pink-400
  }
}
